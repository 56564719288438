const Span = () => <span></span>

const SecWhoWeContant = () => {

  return (
    <div className="col-12 col-lg-6">
        <div className="who-we-contant">
            <div className="dream-dots">
                {Array(7).fill().map((key) => (
                		<Span />
                	))}
            </div>
            <h4 data-aos="fade-up">Empowering DeFi with AI Intelligence</h4>
            <p data-aos="fade-up">StonFI AI brings a new era of decentralized finance, leveraging the power of artificial intelligence to create smarter, more efficient financial ecosystems.</p>
            <p data-aos="fade-up">Our platform uses AI algorithms to optimize trading strategies, enhance security, and provide real-time analytics, giving users a distinct advantage in the rapidly evolving crypto market.</p>
            <p data-aos="fade-up">By integrating AI with DeFi, we offer unprecedented levels of automation, transparency, and profitability for both novice and experienced investors.</p>
            <a data-aos="fade-up" className="btn dream-btn mt-30" href="#">Read More</a>
        </div>
    </div>
  );
}

export default SecWhoWeContant;