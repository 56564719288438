const SecVideoArea = ({img}) => {

  return (
    <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12" data-aos="fade-up">
        <div className="welcome-video-area">
            <div className="welcome-thumb">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/xpDngZ1M3Bk?si=GrvZi70Rij4D9fTO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
    </div>
  );
}

export default SecVideoArea;