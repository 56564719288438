const Span = () => <span></span>

const SecWhoWeContant = () => {

  return (
    <div className="col-12 col-lg-6">
        <div className="who-we-contant">
            <div className="dream-dots" data-aos="fade-up">
                {Array(7).fill().map((key) => (
                		<Span />
                	))}
            </div>
            <h4 data-aos="fade-up">Seamless and Secure Transactions</h4>
            <p data-aos="fade-up">Experience a new standard in DeFi with StonFI AI's convenient, fraud-resistant platform. We prioritize user trust and security, implementing advanced AI-driven protocols to detect and prevent fraudulent activities.</p>
            <p data-aos="fade-up">Our licensed and compliant framework ensures that all transactions are safe and reliable. Enjoy instant transactions with zero-to-low fees, making it easier than ever to participate in the crypto economy.</p>
            <p data-aos="fade-up">With StonFI AI, you can trade and invest confidently, knowing that your assets are protected in a secure, trust-based environment.</p>
            <a className="btn dream-btn mt-30" href="#">Read More</a>
        </div>
    </div>
  );
}

export default SecWhoWeContant;