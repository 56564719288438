import "./tokenomic.css"
import chart from './chart.svg'
const SecTokenomic = () => {

  return (

    <section className="tokenomic-wapper section-padding-0-0 clearfix" id="tokenomic">
      <div className="container">
        <div class="dream-dots justify-content-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="200"><span></span><span></span><span></span><span></span><span></span><span></span><span></span></div>


        <div className="row"><div className="col-md-12" > <h2 data-aos="fade-up" data-aos-delay="300" class="aos-init aos-animate">Tokenomics</h2></div></div>
        <div className="row d-flex aligin-content-center align-items-center">
          <div className="col-md-6 tokenomic-item1" > <div className="chart-container">
            <div className="logo">
              <img src={chart} />
              <div className="sp-animation-logo-1"></div>
            </div>
          </div></div>
          <div className="col-md-6 tokenomic-item2" >
            <div className="tokenomic-item2-item">
              <p>Total Supply: 1,000,000,000 (Tonfi)</p>
              <p>Chain Ton (Tonfi)</p>
              <p>Burn : 200,000,000 Tonfi (20%)</p>
              <p>Listing & liquidity : 100,000,000 Tonfi (10%)</p>
              <p>Advisor : 50,000,000 Tonfi (5%)</p>
              <p>Ecosystem : 500,000,000 Tonfi (50%)</p>
              <p>Marketing, Airdrop : 50,000,000 Tonfi (5%)</p>
              <p>Team/ Dev : 100,000,000 Tonfi (10%)</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}
export default SecTokenomic;