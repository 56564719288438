import SectionHeading from '../../../components/SectionHeading'
import Content from './Content'

const SecOurServices = ({data}) => {

  return (
    <section className="our_services_area section-padding-100-70 clearfix" id="services">
        <div className="container">
            
            <SectionHeading
                title='Our Core Services'
                text='TON employs a sharding architecture that divides the network into smaller shards, allowing for parallel processing of transactions and significantly improving scalability.'
            />
                

            <div className="row">
                {data && data.map((item , key) => (
                    <Content
                        key={key}
                        img={item.img}
                        title={item.title}
                        desc={item.describe}
                    />
                ))}
            </div>
        </div>
    </section>
  );
}

export default SecOurServices;